<template>
  <section id="about" class="route">
    <h1 class="title">Content <span class="accent--text">not found</span></h1>

    <div class="route-content column content--centered">
      <p>
        Was it you, or us? The content you are looking for may have moved or no
        longer exists.
      </p>

      <hr class="divider divider--md" />

      <p>Return <router-link :to="{ name: 'Home' }">home</router-link></p>
    </div>
  </section>
</template>

<script>
export default { name: "NotFound" };
</script>
